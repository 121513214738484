<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body" style="height:50%;">
        <div class="d-flex jc-center"  >
          <dv-decoration-10 style="width: 33.3%; height: 0.0625rem" />
          <div class="d-flex jc-center">
            <dv-decoration-8
              :color="['#568aea', '#000000']"
              style="width: 2.5rem; height: 0.625rem"
            />
            <div class="title">
              <span
                class="title-text"
                style="font-size: 44px; position: initial"
                >{{showTitle}}</span
              >
              <dv-decoration-6
                class="title-bototm"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
                style="width: 3.125rem; height: 0.1rem"
              />
            </div>
            <dv-decoration-8
              :reverse="true"
              :color="['#568aea', '#000000']"
              style="width: 2.5rem; height: 0.625rem"
            />
          </div>
          <dv-decoration-10
            style="width: 33.3%; height: 0.0625rem; transform: rotateY(180deg)"
          />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2"  >
          <div class="d-flex" style="width: 40%">
            <div class="react-left bg-color-blue mr-3">
              <span class="text fw-b" style="font-size: 24px">车间生产订单信息</span>
            </div>
          </div>
          <div style="width: 40%" class="d-flex">
            <div
              class="react-right ml-4"
              style="
width: 10.25rem;
    text-align: right;
    background-color: rgb(15, 19, 37);
              "
            >
              <span class="react-before"></span>
              <span class="text fw-b" style="font-size: 24px;margin-right: 23px;">车间生产现场视频监控</span>
            </div>
            <div
              class="react-left mr-4"
              style="
                width: 6.25rem;
                background-color: #0f1325;
                text-align: right;
              "
            >
              <span class="react-after"></span>
              <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box" style="height:100%;">
          <!-- 第三行数据 -->
          <div class="center-box">
            <dv-border-box-13>
              <!-- <centerLeft1 v-if="!loading&&showdiv=='0'"/> -->
              <!-- <centerLeft2 v-if="!loading&&showdiv=='1'"/> -->
              <orderLeft />
            </dv-border-box-13>
            <dv-border-box-13>
              <div id="play1" class="center-up">
                <iframe
                    :src="playshowurl1"
                  width="100%"
                  height="400px"
                  id="ysopen"
                  allowfullscreen
                >
                </iframe>
              </div>
              <div class="center-up">
                 <iframe
                  :src="playshowurl2"
                  width="100%"
                  height="400px"
                  id="ysopen"
                  allowfullscreen
                >
                </iframe>
              </div>
            </dv-border-box-13>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import {selectWxOrderUserInfor} from "../libs/axios";
import { formatTime } from "../utils/index.js";
//  import centerRightDown1 from "./workshop/centerRightDown1";
// import centerRightDown2 from "./workshop/centerRightDown2";
import orderLeft from "./orderLeft";
// import centerLeft2 from "./workshop/centerLeft2";

//  alert(getStore("id"));
export default {
  data() {
    return {
      selectList: [], // 多选数据
      loading: true,
      dateDay: null,
      playnum1:0,
      playnum2:0,
      showdiv: "0",
      dateYear: null,
      dateWeek: null,
      playshowurl1:"https://open.ys7.com/ezopen/h5/iframe_se?url=ezopen://open.ys7.com/E39129044/22.live&autoplay=0&audio=1&accessToken=at.4iq6qnon2f4u7mhibbwa1ypvcrufvrj6-173yij17vb-1pio907-shgxtnqq5&templete=2",
      playshowurl2:"https://open.ys7.com/ezopen/h5/iframe_se?url=ezopen://open.ys7.com/E34810278/8.live&autoplay=0&audio=1&accessToken=at.4iq6qnon2f4u7mhibbwa1ypvcrufvrj6-173yij17vb-1pio907-shgxtnqq5&templete=2",
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      playurl1: [
        "https://open.ys7.com/ezopen/h5/iframe_se?url=ezopen://open.ys7.com/E34810278/13.live&autoplay=0&audio=1&accessToken=at.4iq6qnon2f4u7mhibbwa1ypvcrufvrj6-173yij17vb-1pio907-shgxtnqq5&templete=2",
        "https://open.ys7.com/ezopen/h5/iframe_se?url=ezopen://open.ys7.com/E39129044/22.live&autoplay=0&audio=1&accessToken=at.4iq6qnon2f4u7mhibbwa1ypvcrufvrj6-173yij17vb-1pio907-shgxtnqq5&templete=2"
       ],
          playurl2: [
         "https://open.ys7.com/ezopen/h5/iframe_se?url=ezopen://open.ys7.com/E34810278/1.live&autoplay=0&audio=1&accessToken=at.4iq6qnon2f4u7mhibbwa1ypvcrufvrj6-173yij17vb-1pio907-shgxtnqq5&templete=2", 
         "https://open.ys7.com/ezopen/h5/iframe_se?url=ezopen://open.ys7.com/E34810278/8.live&autoplay=0&audio=1&accessToken=at.4iq6qnon2f4u7mhibbwa1ypvcrufvrj6-173yij17vb-1pio907-shgxtnqq5&templete=2"],
      // 添加或编辑表单对象初始化数据
      lineGuids: "",
      workShopName: "",
      showTitle:"",
    };
  },
  components: {
    // centerRightDown1,
    // centerRightDown2,
    orderLeft,
    // centerLeft2,
  },
  mounted() {
    this.init();
    this.timeFn();
    this.cancelLoading();
    this.changediv1();
    setInterval(this.setPlayUrl1,70000);
    setInterval(this.setPlayUrl2,50000);
  },
  methods: {
    init() {
      this.selectWxOrderUserInfor();
    },
     selectWxOrderUserInfor() {
       
      this.loading = true;
      var empNo=this.$store.state.deptId;
      selectWxOrderUserInfor(empNo).then((res) => {
          this.showTitle=res.data[0].real_adress;
      });
    },
    setPlayUrl1() {
      let _this=this
      _this.playshowurl1=_this.playurl1[this.playnum1];
       _this.playnum1++;
       if(_this.playnum1==_this.playurl1.length)
       {
         _this.playnum1=0;
       }
    },
   setPlayUrl2() {
      let _this=this
      _this.playshowurl2=_this.playurl2[this.playnum2];
       _this.playnum2++;
       if(_this.playnum2==_this.playurl2.length)
       {
         _this.playnum2=0;
       }
    },
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    changediv1() {
      setInterval(() => {
        if (this.$store.state.divshow == "0") {
          this.showdiv = "1";
          this.$store.commit("setDivshow", "1");
        } else {
          this.showdiv = "0";
          this.$store.commit("setDivshow", "0");
        }
      }, 30000);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/workorder.scss";
</style>